export interface WebsiteConfig {
  title: string;
  description: string;
  coverImage?: string;
  logo: string;
  /**
   * Specifying a valid BCP 47 language helps screen readers announce text properly.
   * See: https://dequeuniversity.com/rules/axe/2.2/valid-lang
   */
  lang: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  /**
   * full url
   */
  facebook?: string;
  /**
   * full url
   */
  twitter?: string;
  /**
   * full url
   */
  github?: string;
  /**
   * full url
   */
  linkedin?: string;
  /**
   * hide or show all email subscribe boxes
   */
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
  /**
   * name and id of the mailchimp email field
   */
  mailchimpEmailFieldName?: string;
  /**
  /**
   * Meta tag for Google Webmaster Tools
   */
  googleSiteVerification?: string;
  /**
  /**
   * Appears alongside the footer, after the credits
   */
  footer?: string;
  /**
   * Shows all post tags in main index view and post view if true
   * Otherwise only shows first (primary) tag
   */
  showAllTags: boolean;
}

const config: WebsiteConfig = {
  title: 'Azure Gems',
  description: 'William Liebenberg',
  //coverImage: 'img/blog-cover.png',
  coverImage: 'images/2021/12/michael-dziedzic-VlZYu3nZIRI-unsplash.jpg',
  logo: 'content/images/azuregems-logo.png',
  lang: 'en',
  siteUrl: 'https://azuregems.io',
  facebook: 'https://www.facebook.com/william.liebenberg81',
  twitter: 'https://twitter.com/william_dotnet',
  github: 'https://github.com/william-liebenberg',
  linkedin: 'https://www.linkedin.com/in/william-liebenberg/',
  showSubscribe: false,
  mailchimpAction: '',
  mailchimpName: '',
  mailchimpEmailFieldName: '',
  googleSiteVerification: 'GoogleCode',
  footer: '',
  showAllTags: true,
};

export default config;
